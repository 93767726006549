<template>
  <div id="agencyBranchManage">
    <CCard class="mb-0">
      <CCardHeader>
        <div class="card-header-actions">
          <CButton color="primary" size="sm" @click="isCollapsed=!isCollapsed">{{isCollapsed ? '登録':'一覧'}}モードへ</CButton>
        </div>
      </CCardHeader>
      <CCollapse :show="!isCollapsed" :duration="200">
        <CCardBody>
          <CRow>
            <CCol col="6">
              <CInput class="mt-0" label="事業所名称" horizontal v-model="formData.agencyBranchName" addLabelClasses="required"/>
            </CCol>
          </CRow>
          <CRow class="mt-0">
            <div class="col-6">
              <div role="group" class="mt-0 form-group form-row">
                <label for="prefectureId" class="col-form-label col-sm-3 required">都道府県</label>
                <div class="col-sm-9">
                  <v-select
                      id="prefectureId"
                      v-model="formData.prefectureId"
                      :options="prefectures"
                      key="prefectureId"
                      label="prefectureName"
                      :reduce="prefectures => prefectures.prefectureId">
                    <template slot="no-options">データがありません。</template>
                  </v-select>
                </div>
              </div>
            </div>
            <CCol col="6">
              <CInput class="mt-0" horizontal label="市区町村" v-model="formData.city" addLabelClasses="required"/>
            </CCol>
          </CRow>
          <CRow class="mt-0">
            <CCol col="6">
              <CInput class="mt-0" horizontal label="連絡先担当者名" v-model="formData.contactName" addLabelClasses="required"/>
            </CCol>
            <CCol col="6">
              <CInput class="mt-0" horizontal label="連絡先電話番号" @keypress="isNumber($event)" v-model="formData.contactTel" addLabelClasses="required"/>
            </CCol>
          </CRow>

          <CRow class="mt-0">
            <CCol col="6">
              <CInput class="mt-0" horizontal label="メモ" v-model="formData.contactMemo" />
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <SaveButtons :id="formData.id" @formInit="formInit" @save="save"/>
        </CCardFooter>
      </CCollapse>
    </CCard>
    <CCard class="p-0 m-0 grid-card">
      <CCardBody class="p-0">
        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword"></MyBeeSearchBar>
        <BeeGridTable
            ref="bee"
            border
            stripe
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="isModal ? windowHeight/2 :gridCardHeight"
            highlightRow
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false">
          <!-- HEADER ソート可能にするために 必要-->
          <template slot-scope="{ column,index }" slot="agencyBranchNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="addressHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="createdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="updatedHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>

          <!-- cell -->
          <template slot-scope="{ row,index }" slot="after">
            <div class="float-center">
              <CButton color="success py-0 mr-1" @click="update(row)">編集</CButton>
            </div>
          </template>
        </BeeGridTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>

import SaveButtons from "@/component/parts/SaveButtons";
import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import CommonMix from '@/views/aprod/js/mixin/CommonUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";

export default {
  name: 'AgencyBranchManage',
  components: {
    MyBeeHeader, MyBeeSearchBar,SaveButtons
  },
  mixins: [CommonMix,BeeMix],
  props:{
    isShow:Boolean,
    agencyId:Number,
    agencyName:String,

    isModal:{type:Boolean,
      default:false}
  },
  data: () => {
    return {
      searchBarPlaceHolder:"事業所名称で検索",
      columns: [
        {title: '事業所名称',key: 'agencyBranchName',headSlot:'agencyBranchNameHead'},
        {title: '住所', key: 'address',headSlot:'addressHead'},
        {title: '登録日時', key: 'created',headSlot:'createdHead'},
        {title: '更新日時', key: 'updated',headSlot:'updatedHead'},
        {title: ' ', key: 'after',slot:"after"},
      ],
      selected: "",
      isCollapsed: true,
      prefectures: [],
      formData: {
        agencyId:null,
        id: null,
        branchName: null,
        prefectureId: null,
        city: null,
        contactName: null,
        contactDept: null,
        contactTel: null,
        contactMemo: null
      },
    }
  },
  methods: {
    formInit: function () {
        this.formData = {agencyId:this.agencyId};
    }, save: function () {
      this.axios.post('/api/agencyBranch/agencyBranchSave', this.formData, {headers: {'Content-Type': 'application/json'}}).then(response => {
      this.goodPaging();
        this.formData.id = response.data.result;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    }, update: function (data) {
      this.axios.get(`/api/agencyBranch/${data.id}`).then(response => {
        this.formData = Object.assign({}, response.data.result);
        this.isCollapsed = false;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },
    goodPaging: function (isPageChange) {
      if (this.agencyId == null) {
        return;
      }
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;

      this.axios.post(`/api/agencyBranch/searchPaging/${this.agencyId}`, this.pagingParams, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        if(this.isModal){
          this.topLeft("#agencyBranchManage");
        }else{
          this.topLeft();
        }

      }).catch(error =>
          console.log(error)
      ).finally(() =>
          this.isLoading = false
      );
    },getAgencyData: function () {

      this.axios.get(`/api/agencyInfo/${this.agencyId}`).then(response => {
        console.log("/api/agency//api/agency//api/agency/",response);
        this.agencyName = response.data.result.agencyName;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);

    }, getOptions: function () {
      this.axios.post('/api/agency/getOptions', {}, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.prefectures = response.data.result.prefectures;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },
  },
  mounted() {
    this.getOptions();
  },
  watch: {
    isShow:function(){
      if(this.isShow){
        this.goodPaging();
      }else{
        this.pagingParams.keyword = null;
      }
    },
    agencyId: function (newValue, oldValue) {
      if (newValue == oldValue) {
        return;
      }
      this.formData.agencyId = newValue;
      if(this.agencyId != null){
        this.isCollapsed = true;
        this.formInit();
        this.goodPaging();
        this.getAgencyData();
      }
    },
    isCollapsed:function(newValue){
      if(newValue){
        this.formInit();
      }
    }
  },
}
</script>
<!--<style scoped>-->
<!--label {-->
<!--  color: #3C4B64;-->
<!--}-->
<!--#depats{-->
<!--  list-style: none;-->
<!--}-->


<!--.pointer:hover {-->
<!--  cursor: pointer;-->
<!--}-->
<!--</style>-->